import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { Container, TextField, Button, Typography, Box, MenuItem, Select, InputLabel, FormControl, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../context/SnackbarContext";

const MovementForm = () => {
    const navigate = useNavigate();
    const { handle_snackbar_open } = useSnackbar();

    const [dateOut, setDateOut] = useState('');
    const [destination, setDestination] = useState('');
    const [curator, setCurator] = useState('');
    const [responsible, setResponsible] = useState('');
    const [artwork, setArtwork] = useState('');
    const [artworks, setArtworks] = useState([]);
    const [sold, setSold] = useState(false);
    const [saleDate, setSaleDate] = useState('');
    const [buyer, setBuyer] = useState('');
    const [seller, setSeller] = useState('');
    const [salePrice, setSalePrice] = useState(0);
    const [error, setError] = useState('');

    useEffect(() => {
        // Fetch the list of artworks on component mount
        const fetchArtworks = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/artworks`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                const data = await response.json();
                setArtworks(data);
            } catch (error) {
                console.error('Errore nel caricamento dei quadri:', error);
            }
        };

        fetchArtworks();
    }, []);

    const fetchArtworkDetails = async (artworkId) => {
        // Fetch detailed artwork information, including the "sold" flag
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/artworks/${artworkId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data.lastMovement)
                setSold(data.lastMovement?.sold || false); // Update the sold flag based on API response
            } else {
                console.error('Errore nel recupero dei dettagli del quadro');
            }
        } catch (error) {
            console.error('Errore nella chiamata API:', error);
        }
    };

    const handleArtworkChange = (artworkId) => {
        setArtwork(artworkId);
        fetchArtworkDetails(artworkId); // Fetch details for the selected artwork
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const movementData = sold
            ? {
                sale_date: saleDate,
                sold,
                buyer,
                seller,
                sale_price: salePrice,
                artwork_id: artwork,
            }
            : {
                exit_date: dateOut,
                destination,
                curator_name: curator,
                manager_name: responsible,
                artwork_id: artwork,
            };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/movements`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(movementData),
            });

            if (response.ok) {
                handle_snackbar_open(sold ? 'Vendita registrata con successo!' : 'Movimento aggiunto con successo!');
                navigate('/movements');
            } else {
                handle_snackbar_open('Errore nell\'aggiungere il movimento');
            }
        } catch (error) {
            setError('Errore nell\'aggiungere il movimento. Riprova.');
        }
    };

    return (
        <Layout>
            <Container maxWidth="sm">
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Aggiungi un Movimento
                    </Typography>

                    {error && (
                        <Typography color="error" variant="body2" gutterBottom>
                            {error}
                        </Typography>
                    )}

                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth required>
                                    <InputLabel id="artwork-label">Seleziona Quadro</InputLabel>
                                    <Select
                                        labelId="artwork-label"
                                        value={artwork}
                                        onChange={(e) => handleArtworkChange(e.target.value)}
                                        label="Seleziona Quadro"
                                    >
                                        {artworks.map((art) => (
                                            <MenuItem key={art.id} value={art.id}>
                                                {art.name} - {art.creation_date}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sold}
                                            onChange={(e) => setSold(e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="Venduto"
                                />
                            </Grid>

                            {!sold && (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Data Uscita"
                                            variant="outlined"
                                            fullWidth
                                            type="date"
                                            value={dateOut}
                                            onChange={(e) => setDateOut(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Destinazione"
                                            variant="outlined"
                                            fullWidth
                                            value={destination}
                                            onChange={(e) => setDestination(e.target.value)}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Curatore"
                                            variant="outlined"
                                            fullWidth
                                            value={curator}
                                            onChange={(e) => setCurator(e.target.value)}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Responsabile"
                                            variant="outlined"
                                            fullWidth
                                            value={responsible}
                                            onChange={(e) => setResponsible(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                </>
                            )}

                            {sold && (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Data Vendita"
                                            variant="outlined"
                                            fullWidth
                                            type="date"
                                            value={saleDate}
                                            onChange={(e) => setSaleDate(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Compratore"
                                            variant="outlined"
                                            fullWidth
                                            value={buyer}
                                            onChange={(e) => setBuyer(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Venditore"
                                            variant="outlined"
                                            fullWidth
                                            value={seller}
                                            onChange={(e) => setSeller(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Prezzo di Vendita"
                                            variant="outlined"
                                            fullWidth
                                            type="number"
                                            value={salePrice}
                                            onChange={(e) => setSalePrice(e.target.value)}
                                        />
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                >
                                    {sold ? 'Registra Vendita' : 'Aggiungi Movimento'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Container>
        </Layout>
    );
};

export default MovementForm;
