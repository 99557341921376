import React, { useEffect, useState } from 'react';
import {
    Container,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Divider,
    Button,
    CircularProgress,
    Grid,
} from '@mui/material';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';

const ArtworkList = () => {
    const [artworks, setArtworks] = useState([]);
    const [imageUrls, setImageUrls] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchArtworks = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/artworks`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setArtworks(data);

            // Prepara lo stato con i loader per tutte le immagini
            const initialImageUrls = data.reduce((acc, artwork) => {
                acc[artwork.photo] = { loading: true, url: null };
                return acc;
            }, {});
            setImageUrls(initialImageUrls);

            // Recupera le immagini una alla volta
            data.forEach((artwork) => fetchImage(artwork.photo));
        };

        fetchArtworks();
    }, []);

    const fetchImage = async (photo) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/image/${photo}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.ok) {
                const json = await response.json();
                setImageUrls((prev) => ({
                    ...prev,
                    [photo]: { loading: false, url: `data:image/jpeg;base64,${json.image}` },
                }));
            } else {
                setImageUrls((prev) => ({
                    ...prev,
                    [photo]: { loading: false, url: null },
                }));
            }
        } catch {
            setImageUrls((prev) => ({
                ...prev,
                [photo]: { loading: false, url: null },
            }));
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}/${month}/${year}`;
    };

    const handleEdit = (artworkId) => {
        navigate(`/artworks/${artworkId}`);
    };

    const handleDelete = async (artworkId) => {
        const confirmDelete = window.confirm("Sei sicuro di voler eliminare quest'opera?");
        if (confirmDelete) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/artworks/${artworkId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.ok) {
                setArtworks(artworks.filter((artwork) => artwork.id !== artworkId));
            } else {
                alert("Errore nell'eliminazione dell'opera.");
            }
        }
    };

    const isSold = (artwork) => {
        return artwork.lastMovement && artwork.lastMovement.sold === true;
    };

    return (
        <Layout>
            <Container maxWidth="lg">
                <Typography variant="h4" gutterBottom align="center">
                    Elenco delle Opere
                </Typography>
                <Grid container spacing={4}>
                    {artworks.map((artwork) => (
                        <Grid item xs={12} sm={6} md={4} key={artwork.id}>
                            <Card>
                                {imageUrls[artwork.photo]?.loading ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: 200,
                                            backgroundColor: '#f0f0f0',
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={imageUrls[artwork.photo]?.url}
                                        alt={artwork.name}
                                        sx={{ objectFit: 'cover' }}
                                    />
                                )}
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        {artwork.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Data di Realizzazione: {formatDate(artwork.date)}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Dimensioni: {artwork.dimensions} cm
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Tecnica: {artwork.technique}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Maggiorazione Percentuale: {artwork.markup}%
                                    </Typography>

                                    <Divider style={{margin: '20px 0'}}/>

                                    {isSold(artwork) ? (
                                        <div
                                            style={{
                                                backgroundColor: '#60e834',
                                                padding: '10px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            <Typography variant="body2" color="text.secondary" align="center">
                                                VENDUTO
                                            </Typography>
                                        </div>
                                    ) : artwork.lastMovement.exit_date ? (
                                        <div
                                            style={{
                                                backgroundColor: '#f0f0f0',
                                                padding: '10px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                Posizione attuale
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Data uscita: {formatDate(artwork.lastMovement.exit_date)}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Destinazione: {artwork.lastMovement.destination}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Curatore: {artwork.lastMovement.curator_name}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Data rientro: {artwork.lastMovement.return_date}
                                            </Typography>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                backgroundColor: '#f0f0f0',
                                                padding: '10px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                align="center"
                                            >
                                                Nessun movimento registrato.
                                            </Typography>
                                        </div>
                                    )}

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleEdit(artwork.id)}
                                        style={{marginTop: '10px', marginRight: '10px'}}
                                    >
                                        Modifica
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleDelete(artwork.id)}
                                        style={{marginTop: '10px'}}
                                    >
                                        Elimina
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Layout>
    );
};

export default ArtworkList;
