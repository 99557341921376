import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import {
    Table, TableBody, TableCell, TableHead, TableRow,
    TableContainer, Paper, TablePagination, Button, Dialog,
    DialogActions, DialogContent, DialogTitle, TextField,
    Checkbox, FormControlLabel, IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';  // Importa l'icona della penna

const MovementList = () => {
    const [movements, setMovements] = useState([]);
    const [filteredMovements, setFilteredMovements] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [activeFilter, setActiveFilter] = useState('all');
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedMovement, setSelectedMovement] = useState(null);

    useEffect(() => {
        const fetchMovements = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/movements`, {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
                });
                const data = await response.json();
                setMovements(data);
                setFilteredMovements(data);
            } catch (error) {
                console.error('Errore nel fetch dei movimenti:', error);
            }
        };

        fetchMovements();
    }, []);

    const filterInCasa = () => {
        const currentDate = new Date();
        const inCasaMovements = movements.filter(movement =>
            (!movement.date_out || movement.date_out === '') ||
            (movement.date_return && new Date(movement.date_return) < currentDate)
        ).filter(movement => !movement.sold); // Escludi i movimenti venduti
        setFilteredMovements(inCasaMovements);
        setCurrentPage(0);
        setActiveFilter('inCasa');
    };

    const filterInEsposizione = () => {
        const currentDate = new Date();
        const inEsposizioneMovements = movements.filter(movement =>
            movement.date_out && (!movement.date_return || new Date(movement.date_return) > currentDate)
        ).filter(movement => !movement.sold); // Escludi i movimenti venduti
        setFilteredMovements(inEsposizioneMovements);
        setCurrentPage(0);
        setActiveFilter('inEsposizione');
    };

// Filtro solo i quadri venduti
    const filterVenduti = () => {
        const vendutiMovements = movements.filter(movement => movement.sold);
        setFilteredMovements(vendutiMovements);
        setCurrentPage(0);
        setActiveFilter('venduti');
    };

    const showAllMovements = () => {
        setFilteredMovements(movements);
        setCurrentPage(0);
        setActiveFilter('all');
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleEditClick = (movement) => {
        // Quando apri il popup, assicurati che le date siano nel formato 'YYYY-MM-DD'
        setSelectedMovement({
            ...movement,
            date_out: movement.date_out ? new Date(movement.date_out).toISOString().split('T')[0] : '',
            date_return: movement.date_return ? new Date(movement.date_return).toISOString().split('T')[0] : ''
        });
        setEditDialogOpen(true);
    };

    const handleDialogClose = () => {
        setEditDialogOpen(false);
        setSelectedMovement(null);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setSelectedMovement(prevState => {
            const updatedMovement = {
                ...prevState,
                [name]: type === 'checkbox' ? checked : value
            };

            // Se il campo "Venduto" viene deselezionato, resetta "Compratore" e "Prezzo di vendita"
            if (name === 'sold' && !checked) {
                updatedMovement.buyer = '';
                updatedMovement.sale_price = '';
            }

            return updatedMovement;
        });
    };

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/movements/${selectedMovement.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(selectedMovement)
            });

            if (response.ok) {
                const updatedMovements = movements.map(movement =>
                    movement.id === selectedMovement.id ? selectedMovement : movement
                );
                setMovements(updatedMovements);
                setFilteredMovements(updatedMovements);
                handleDialogClose();
            } else {
                console.error('Errore nel salvataggio delle modifiche');
            }
        } catch (error) {
            console.error('Errore nella richiesta di modifica:', error);
        }
    };

    return (
        <Layout>
            <h2>Lista Movimenti</h2>

            <div style={{marginBottom: '20px'}}>
                <Button variant={activeFilter === 'all' ? 'contained' : 'outlined'} onClick={showAllMovements}
                        style={{marginRight: '10px'}}>
                    Mostra Tutti
                </Button>
                <Button variant={activeFilter === 'inCasa' ? 'contained' : 'outlined'} onClick={filterInCasa}
                        style={{marginRight: '10px'}}>
                    Quadri in Casa
                </Button>
                <Button variant={activeFilter === 'inEsposizione' ? 'contained' : 'outlined'}
                        onClick={filterInEsposizione} style={{marginRight: '10px'}}>
                    Quadri in Esposizione
                </Button>
                <Button variant={activeFilter === 'venduti' ? 'contained' : 'outlined'} onClick={filterVenduti}>
                    Quadri Venduti
                </Button>
            </div>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Quadro</TableCell>
                            <TableCell>Curatore</TableCell>
                            <TableCell>Destinazione</TableCell>
                            <TableCell>Data Uscita</TableCell>
                            <TableCell>Data Ritorno</TableCell>
                            <TableCell>Responsabile</TableCell>
                            <TableCell>Venduto</TableCell>
                            <TableCell>Compratore</TableCell>
                            <TableCell>Prezzo Vendita</TableCell>
                            <TableCell>Azione</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredMovements
                            .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                            .map((movement) => (
                                <TableRow key={movement.id}>
                                    <TableCell>{movement.artwork_name}</TableCell>
                                    <TableCell>{movement.curator}</TableCell>
                                    <TableCell>{movement.destination}</TableCell>
                                    <TableCell>{movement.date_out ? new Date(movement.date_out).toLocaleDateString() : ''}</TableCell>
                                    <TableCell>{movement.date_return ? new Date(movement.date_return).toLocaleDateString() : ''}</TableCell>
                                    <TableCell>{movement.responsible}</TableCell>
                                    <TableCell>{movement.sold ? 'Sì' : 'No'}</TableCell>
                                    <TableCell>{movement.buyer || ''}</TableCell>
                                    <TableCell>{movement.sale_price || ''}</TableCell>
                                    <TableCell>
                                        {/* Icona Modifica */}
                                        <IconButton onClick={() => handleEditClick(movement)}>
                                            <EditIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>

                <TablePagination
                    component="div"
                    count={filteredMovements.length}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Righe per pagina"
                />
            </TableContainer>

            {/* Dialogo per la modifica */}
            <Dialog open={editDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Modifica Movimento</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Curatore"
                        name="curator"
                        value={selectedMovement?.curator || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Destinazione"
                        name="destination"
                        value={selectedMovement?.destination || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Data Uscita"
                        name="date_out"
                        type="date"
                        value={selectedMovement?.date_out || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        slotProps={{
                            inputLabel: {
                                shrink: true, // Ensures the label behaves as expected for dates
                                onClick: () => console.log("Label clicked"),
                            }
                        }}
                    />
                    <TextField
                        label="Data Ritorno"
                        name="date_return"
                        type="date"
                        value={selectedMovement?.date_return || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        slotProps={{
                            inputLabel: {
                                shrink: true, // Ensures the label behaves as expected for dates
                                onClick: () => console.log("Label clicked"),
                            }
                        }}
                    />
                    <TextField
                        label="Responsabile"
                        name="responsible"
                        value={selectedMovement?.responsible || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedMovement?.sold || false}
                                onChange={handleInputChange}
                                name="sold"
                            />
                        }
                        label="Venduto"
                    />
                    {selectedMovement?.sold && (
                        <>
                            <TextField
                                label="Compratore"
                                name="buyer"
                                value={selectedMovement?.buyer || ''}
                                onChange={handleInputChange}
                                fullWidth
                                margin="dense"
                            />
                            <TextField
                                label="Prezzo Vendita"
                                name="sale_price"
                                type="number"
                                value={selectedMovement?.sale_price || ''}
                                onChange={handleInputChange}
                                fullWidth
                                margin="dense"
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Annulla
                    </Button>
                    <Button onClick={handleSaveChanges} color="primary">
                        Salva
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default MovementList;
